import { memo, useState, useEffect } from "react";
import GlobalModal from "../../../components/modalBox";
import AddTrainerAttendance from "./add-trainer-attendance";
import { api, resolveUrl } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { CircularProgress } from "@mui/material";

const EditCheckIns = memo(({ modalOpen, setModalOpen, id, onUpdateSuccess }) => {
  console.log(id,"id");
  
  const [attendanceData, setAttendanceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch attendance data when modal opens and we have an ID
  useEffect(() => {
    if (modalOpen && id) {
      fetchAttendanceData();
    }
  }, [modalOpen, id]);

  const fetchAttendanceData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(
        resolveUrl(AdminApi.TEACHERS_ATTENDANCE_DETAIL,id)
      );
      console.log("Attendance data:", response.data.data);
      setAttendanceData(response.data.data);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setError("Failed to load attendance data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setAttendanceData(null);
    setError(null);
  };

  // Handle successful updates
  const handleUpdateSuccess = () => {
    if (onUpdateSuccess) {
      onUpdateSuccess((prev) => !prev);
    }
    handleCloseModal();
  };

  return (
  
      loading ? (
        <div className="text-center p-4">
          <CircularProgress />
          <p>Loading attendance data...</p>
        </div>
      )  : attendanceData ? (
        <AddTrainerAttendance
          open={true}
          closeModal={handleCloseModal}
          setReRender={handleUpdateSuccess}
          isEditing={true}
          editData={attendanceData}
          id={id}
        />
      ) : null
  );
});

export default EditCheckIns;