import { Alert, CircularProgress, Grid } from "@mui/material";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../config/apis";
import ActionButtons from "./ActionButtons";
import Pagination from "@mui/material/Pagination";

export default function Datatable(params) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [meta, setMeta] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  const refresh = () => {
    fetchData();
  };
  console.log(params,"params");
  

  const showActionColumns = params.actionColumns !== false;

  const actionCol = {
    name: "Actions",
    width: "100px",
    cell: (row) => (
      <ActionButtons
        id={row.id}
        actions={params.actions}
        refreshHandle={refresh}
        onView={params.onView}
        onEdit={params.onEdit}
        onDelete={params.onDelete}
        state={state}
        module={params.module}
      />
    ),
  };

  const columns = [...params.tableColumns];
  if (showActionColumns) {
    columns.push(actionCol);
  }
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const parameters = {
      page: page,
      limit: rowsPerPage,
      filters: {
        ...params.filters,
      },
      sort_order: params.sortOrder,
      assessment_weight_age_id: params.qurreyParam,
      ...(params.qureyParam ?? ""),
    };

    await api
      .get(`${params.dataUrl}`, { params: parameters })
      .then((res) => {
        setData(res.data.data.attendance || res.data.data);
        if (params.setTableData) {
          params.setTableData(res.data.data ?? []);
        }
        if (res.data.data.meta) {
          // setPage(res.data.data.meta.currentPage);
          setTotalPages(res.data.data.meta.totalItems);
          setMeta(res.data.data.meta);
        } else {
          // setPage(res.data.meta.currentPage);
          setMeta(res.data.meta);
          setTotalPages(res.data.meta.totalItems);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [
    page,
    rowsPerPage,
    params.filters,
    params.sortOrder,
    params.dataUrl,
    params.qureyParam,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, params.refreshHandle]);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const handlePageChange = (page) => {
    setPage(page);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const showPagination = params.pagination !== false;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = parseInt(searchParams.get("page")) || 1;
    setPage(pageParam);
  }, []);

  return (
    <div>
      {state && (
        <Alert variant="outlined" severity={state.status}>
          {state.message}
        </Alert>
      )}
      <DataTable
        columns={columns}
        className={params.customClass ?? ""}
        data={data ?? ""}
        highlightOnHover
        progressPending={isLoading}
        pagination={showPagination}
        paginationServer={true}
        paginationTotalRows={totalPages}
        onChangePage={(page) => handlePageChange(page)}
        onChangeRowsPerPage={handleRowsPerPageChange}
        progressComponent={<Loader />}
      />
      {/* {!isLoading && showPagination && data.length > 0 && meta && (
        <Grid
          container
          marginTop={2}
          justifyContent={"space-between"}
          className="pagination_wrap_container"
        >
          <Grid item xs={4}>
            <span className={"count"}>
              {`${
                (meta?.currentPage - 1) * meta?.itemsPerPage + 1
              } - ${Math.min(
                meta?.currentPage * meta?.itemsPerPage,
                meta?.totalItems
              )} of ${meta?.totalItems}`}
            </span>
          </Grid>
          <Grid item xs={8} container justifyContent={"flex-end"}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              variant="outlined"
              className="pagination_wrap"
            />
          </Grid>
        </Grid>
      )} */}
    </div>
  );
}
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 250,
      }}
    >
      <CircularProgress sx={{ color: "#001c28" }} />
    </div>
  );
};
