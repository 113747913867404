import Datatable from "../../../../components/dataTables/DataTable";
import AdminApi from "../../../../config/apis/admin";
import React from "react";
import styles from "./style.module.css";
import { Box, Grid, Typography } from "@mui/material";
import ImageView from "../../../../components/imageShow";
import { limitStringLength, toTitleCase } from "../../../../helper/helperFunction";
import Tooltip from "@mui/material/Tooltip";

export default function ViewTodaysClasses() {
  const tableColumns = [
    {
      cell: (row) => (
        <>
          <ImageView imageName={row.profile_image} name={row.teacher_name} />
          <Tooltip title={toTitleCase(`${row.teacher_name}`)} arrow>
            <span>{toTitleCase(`${row.teacher_name}`)}</span>
          </Tooltip>
        </>
      ),
      selector: "teacher_name",
      name: "Teachers Name",
      sortable: true,
      minWidth: "180px",
    },
    {
      cell: (row) => (
        <>
        <Tooltip title={row.location} arrow>
          {row.location && limitStringLength(row.location, 15)}
        </Tooltip>
        </>
      ),
      selector: "location",
      name: "Location",
      sortable: true,
    },
    {
      cell: (row) => (
        <>
        <Tooltip title={row.course} arrow>
          {row.course && limitStringLength(row.course, 15)}
        </Tooltip>
        </>
      ),
      selector: "course",
      name: "Course",
      sortable: true,
    },
    {
      cell: (row) => (
        <>
        <Tooltip title={row.cohort} arrow>
          {row.cohort && limitStringLength(row.cohort, 15)}
        </Tooltip>
        </>
      ),
      selector: "cohort",
      name: "Cohort",
      sortable: true,
    },
  
    {
      selector: (row) => (
          <span>{row.students}</span>
      ),
      selector: "students",
      name: "Students",
      sortable: true,
    },
  ];
  return (
    <>
      <Box className={`${styles.topskillsMain}`}>
        <Grid
          sx={12}
          item
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"10px"}
        >
          <Typography variant="h3" className={styles.fs_h3}>
            Today’s classes
          </Typography>
        </Grid>
        <div
          className="page-container table-phases"
          style={{ paddingBottom: "0" }}
        >
          <Datatable
            tableColumns={tableColumns}
            dataUrl={AdminApi.DASH_TODAYS_CLASSES}
            actionColumns={false}
            module="manage-job-skills"
            pagination={false}
            // filters={filterData}
          />
        </div>
      </Box>
    </>
  );
}
