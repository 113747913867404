import React, { useEffect, useState } from "react";
import { Typography, Box, Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import GlobalModal from "../../../components/modalBox";
import styles from "./styles.module.css";
import emaillogo from "../../../static/images/emaillogo.png";

export const ViewBroadcastDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [viewData, setViewData] = useState(null);

  const handleCloseModal = () => {
    props.setModalOpen(false);
    queryParams.delete("id");
    navigate({ pathname: location.pathname, search: queryParams.toString() });
    setViewData(null);
  };

  useEffect(() => {
    if (props.viewId) {
      const fetchBroadcastDetails = async () => {
        try {
          const response = await api.get(
            resolveUrl(AdminApi.BROADCAST_DETAILS, props.viewId)
          );
          setViewData(response.data.data);
        } catch (error) {
          console.error("Error fetching broadcast details:", error);
        }
      };

      fetchBroadcastDetails();
    }
  }, [props.viewId, props.modalOpen]);

  return (
    <>
      <GlobalModal
        padding={"8px"}
        open={props.modalOpen}
        onClose={handleCloseModal}
        width={460}
        className={styles.modalContainer}
      >
        {viewData && (
          <Box className={styles.modalBody}>
            <div className={styles.modalImageContainer}>
              <img src={emaillogo} alt="Broadcast Image" />
            </div>
            <Typography className={styles.descriptionContent}>
              Subject: {viewData.subject}
            </Typography>
            <Divider className={styles.divider} />
            <Typography
              className={styles.descriptionContent}
              style={{ fontSize: "14px" }}
              dangerouslySetInnerHTML={{ __html: viewData.message }}
            />
          </Box>
        )}
      </GlobalModal>
    </>
  );
};
