import React, { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  Modal,
  Select,
  TextField,
  Button,
  Fade,
  Grid,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import AdminApi from "../../../config/apis/admin";
import { api, resolveUrl } from "../../../config/apis";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { formatDateYYMMDD } from "../../../helper/helperFunction";
import Form from "../../../components/forms/Forms";

const AddTrainerAttendance = ({ open, closeModal, setReRender, isEditing = false, editData = null, id = null }) => {
  const [formData, setFormData] = useState({
    trainer: "",
    section: "",
    date: "",
    punch_in: "",
    punch_out: "",
  });    

  const [trainers, setTrainers] = useState([]);
  const [sections, setSections] = useState([]);
  const [formSuccess, setFormSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // Format date string to YYYY-MM-DD
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  // Format time string from ISO to HH:MM
  const formatTimeFromISO = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
  };

  // Handle form success
  useEffect(() => {
    if (formSuccess) {
      closeModal();
      setFormSuccess(false);
      setFormData({
        trainer: "",
        section: "",
        date: "",
        punch_in: "",
        punch_out: "",
      });
      setReRender((prevState) => !prevState);
    }
  }, [formSuccess, closeModal, setReRender]);

  // Initialize form and fetch dropdown data when modal opens
  useEffect(() => {
    if (open) {
      fetchDropdownData();

      // Reset form if not editing
      if (!isEditing) {
        setFormData({
          trainer: "",
          section: "",
          date: "",
          punch_in: "",
          punch_out: "",
        });
      }
    }
  }, [open, isEditing]);

  // Populate form with edit data when available
  useEffect(() => {
    if (isEditing && editData) {
      console.log("Setting edit data:", editData);
      
      // Fetch sections for the trainer if there's a teacher_id
      if (editData.teacher_id) {
        fetchSections(editData.teacher_id);
      }
      
      setFormData({
        trainer: editData.teacher_id,
        section: editData.section_id,
        date: formatDateForInput(editData.date),
        punch_in: formatTimeFromISO(editData.punch_in_time),
        punch_out: formatTimeFromISO(editData.punch_out_time),
      });
    }
  }, [isEditing, editData]);

  const fetchDropdownData = async () => {
    setLoading(true);
    try {
      const trainersRes = await api.get(resolveUrl(AdminApi.TEACHERS_LIST));
      console.log("Trainers:::", trainersRes.data.data);
      setTrainers(trainersRes.data.data);
    } catch (error) {
      console.error("Error fetching trainers:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSections = async (trainerId) => {
    if (!trainerId) return;
    
    setLoading(true);
    try {
      const sectionsRes = await api.get(resolveUrl(AdminApi.TEACHERS_SECTIONS.replace(":id", trainerId)));
      console.log("Sections for trainer:", sectionsRes.data.data);
      setSections(sectionsRes.data.data);
    } catch (error) {
      console.error("Error fetching sections:", error);
      setSections([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    
    if (name === "trainer") {
      fetchSections(value);
      setFormData((prev) => ({ ...prev, section: "" }));
    }
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      date: date ? formatDateYYMMDD(date) : "",
    }));
  };

  const preparePayload = () => {
    const payload = {
      teacher_id: formData.trainer,
      section_id: formData.section,
      date: formData.date ? new Date(formData.date).toISOString() : "",
    };

    // Convert punch_in and punch_out times to UTC format
    if (formData.punch_in && formData.date) {
      const punchInDate = new Date(`${formData.date}T${formData.punch_in}:00`);
      payload.punch_in_time = punchInDate.toISOString();
    }

    if (formData.punch_out && formData.date) {
      const punchOutDate = new Date(`${formData.date}T${formData.punch_out}:00`);
      payload.punch_out_time = punchOutDate.toISOString();
    }

    return payload;
  };

  // Determine the submission URL based on whether we're editing or adding
  const getSubmitUrl = () => {
    if (isEditing && id) {
      return `${AdminApi.TEACHERS_ATTENDANCE}/${id}`;
    }
    return AdminApi.TEACHERS_ATTENDANCE;
  };

  // Determine the method to use (PUT for update, POST for create)
  const getSubmitMethod = () => {
    return isEditing ? "PUT" : "POST";
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <Box className="popup" maxWidth={600}>
        <button onClick={closeModal} className="close_btn">
          Close
        </button>
        <h2>{isEditing ? "Edit Trainer Attendance" : "Add Trainer Attendance"}</h2>
        
        {loading && <CircularProgress style={{ margin: "20px auto", display: "block" }} />}
        
        <Form
          successPath="/admin/trainer-checkins" 
          submitUrl={getSubmitUrl()}
          method={getSubmitMethod()}
          formData={preparePayload()}
          setFormSuccess={setFormSuccess}
        >
          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xl={2} sm={3.1} mb={1}>
              <InputLabel sx={{ textAlign: "left" }}>Trainer*</InputLabel>
            </Grid>
            <Grid item xl={6} sm={8}>
              <Select 
                name="trainer" 
                fullWidth 
                value={formData.trainer} 
                onChange={handleInputChange}
                disabled={isEditing}
              >
                {trainers.map((trainer) => (
                  <MenuItem key={trainer.id} value={trainer.id}>{trainer.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xl={2} sm={3.1} mb={1}>
              <InputLabel sx={{ textAlign: "left" }}>Section*</InputLabel>
            </Grid>
            <Grid item xl={6} sm={8}>
              <Select 
                name="section" 
                fullWidth 
                value={formData.section} 
                onChange={handleInputChange} 
                disabled={!sections.length || isEditing}
              >
                {sections.map((section) => (
                  console.log(section.name,"section.name"),
                  <MenuItem key={section.id} value={section.id}>{section.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xl={2} sm={3.1} mb={1}>
              <InputLabel sx={{ textAlign: "left" }}>Date*</InputLabel>
            </Grid>
            <Grid item xl={6} sm={8}>
              <DatePicker
                calendarAriaLabel="Toggle calendar"
                clearAriaLabel="Clear value"
                dayAriaLabel="Day"
                monthAriaLabel="Month"
                nativeInputAriaLabel="Date"
                selected={formData.date ? new Date(formData.date) : null}
                value={formData.date ? new Date(formData.date) : null}
                onChange={handleDateChange}
                yearAriaLabel="Year"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xl={2} sm={3.1} mb={1}>
              <InputLabel sx={{ textAlign: "left" }}>Punch In Time</InputLabel>
            </Grid>
            <Grid item xl={6} sm={8}>
              <TextField 
                type="time" 
                fullWidth 
                name="punch_in" 
                value={formData.punch_in} 
                onChange={handleInputChange} 
                inputProps={{ step: 300 }} 
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container sx={{ mb: 3, alignItems: "center" }}>
            <Grid item xl={2} sm={3.1} mb={1}>
              <InputLabel sx={{ textAlign: "left" }}>Punch Out Time</InputLabel>
            </Grid>
            <Grid item xl={6} sm={8}>
              <TextField 
                type="time" 
                fullWidth 
                name="punch_out" 
                value={formData.punch_out} 
                onChange={handleInputChange} 
                inputProps={{ step: 300 }} 
              />
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Modal>
  );
};

export default AddTrainerAttendance;