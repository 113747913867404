import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Chip,
  CircularProgress
} from '@mui/material';
import Form from "../../../components/forms/Forms";
import AdminApi from '../../../config/apis/admin';
import { api, resolveUrl } from "../../../config/apis";


const AddEmailModal = ({ open, onClose, onSubmit }) => {
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Fetch existing emails when component mounts
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        setIsLoading(true);
        // Remove 'admin/' prefix from the endpoint
        const response = await api.get(resolveUrl(AdminApi.GET_TEACHERS_EMAIL));
        // Assuming the response contains an array of emails
        if (response.data) {
          setEmails(response.data.emails);
        }
      } catch (error) {
        console.error('Error fetching emails:', error);
        // Optionally set an error state
        setErrors({
          fetch: 'Failed to fetch existing emails'
        });
      } finally {
        setIsLoading(false);
      }
    };

    // Only fetch if modal is open
    if (open) {
      fetchEmails();
    }
  }, [open]);

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmailInput(inputValue);
  };

  // Basic email validation
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const addEmailToList = () => {
    const newEmail = emailInput.trim();

    // Check if email is valid and not already in the list
    if (newEmail && 
        isValidEmail(newEmail) && 
        !emails.includes(newEmail)) {
      setEmails(prev => [...prev, newEmail]);
      setEmailInput(''); // Clear input after adding
    }
  };

  const handleKeyDown = (e) => {
    // Add email when comma is pressed or Enter is pressed
    if (e.key === ',' || e.key === 'Enter') {
      e.preventDefault(); // Prevent default comma input
      addEmailToList();
    }
  };

  const handleChipDelete = (chipIndex) => {
    const updatedemails = emails.filter((_, index) => index !== chipIndex);
    setEmails(updatedemails);
  };

  // Prepare payload for API submission
  const preparePayload = () => {
    return {
      email: emails
    };
  };

  // Handle form success
  const setFormSuccess = (response) => {
    console.log('Form submitted successfully', response);
    onClose(); // Close modal on success
  };

  const handleSubmit = () => {
    // Validate emails before submission
    const validEmails = emails.filter(isValidEmail);

    if (validEmails.length === 0) {
      setErrors({
        emails: 'Please enter at least one valid email'
      });
      return;
    }

    // Clear any previous errors
    setErrors({});

    // Submit the form
    onSubmit(preparePayload());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className="popup" maxWidth={600}>
        <button onClick={onClose} className="close_btn">
          Close
        </button>

        <Grid container spacing={2}>
          <Grid item xl={2} sm={3}>
            <InputLabel>Add Emails*</InputLabel>
          </Grid>
          <Grid item xl={6} sm={8} className="exp-width">
            <TextField
              fullWidth
              sx={{ maxWidth: "725px" }}
              value={emailInput}
              placeholder="Enter email and press comma to add"
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
            />
            
            {isLoading ? (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Box marginTop={2}>
                {emails.map((emailsItem, index) => (
                  <Chip
                    key={index}
                    label={emailsItem}
                    onDelete={() => handleChipDelete(index)}
                    style={{ margin: 4 }}
                    color={isValidEmail(emailsItem) ? 'primary' : 'error'}
                  />
                ))}
              </Box>
            )}

            {errors.fetch && (
              <FormHelperText error>{errors.fetch}</FormHelperText>
            )}
            {errors.emails && (
              <FormHelperText error>{errors.emails}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Form
          successPath="/admin/trainer-checkins"
          submitUrl={AdminApi.ADD_TEACHERS_EMAIL}
          formData={preparePayload()}
          setFormSuccess={setFormSuccess}
          onSubmit={handleSubmit}
        />
      </Box>
    </Modal>
  );
};

export default AddEmailModal;