import { Box, Grid, Button, Tooltip,
  Chip, } from "@mui/material";
import React, { useState } from "react";
import Datatable from "../../../components/dataTables/DataTable";
import { DateView } from "../../../components/DateView/DateView";
import "react-datepicker/dist/react-datepicker.css"; // Import the DatePicker CSS
import "../../../static/css/style.css";
import {
  formatDate,
  getDatesById,
  GetUserRole,
} from "../../../helper/helperFunction";
import DatePicker from "react-datepicker";
import { Typography } from "@mui/material";
import styles from "./style.module.css";
import AdminApi from "../../../config/apis/admin";
import SelectOptionsApi from "../../../components/SelectApi";
import EditCheckIns from "./edit";
import AddTrainerAttendance from "./add-trainer-attendance";
import AddEmailModal from "./add-emails";
import { SERVER_KEY } from "../../../config/constants/EnviormentVariables";


export default function CheckIns() {

  const role = GetUserRole();
  const tableColumns = [
    {
      cell: (row) => (
        <Typography variant="body1" className="font-s-13">
          <DateView date={row.date} format="ddd, DD MMM, YYYY" />
        </Typography>
      ),
      name: "Date",
      sortable: true,
      selector: "date",
    },
    {
      cell: (row) => (
        <Typography variant="body1" className="font-s-13">
          <DateView date={row.punch_in} format="hh:mm A" />
        </Typography>
      ),
      name: "In",
      sortable: true,
      selector: "punch_in",
    },

    {
      cell: (row) => (
        <Typography variant="body1" className="font-s-13">
          {row.punch_out ? (
            <DateView date={row.punch_out} format="hh:mm A" />
          ) : (
            "-"
          )}
        </Typography>
      ),
      name: "Out",
      sortable: true,
      selector: "punch_out",
    },
    {
      cell: (row) => (
        <Typography variant="body1" className="font-s-13">
          {row.working_hours ? (
            parseFloat(row.working_hours).toFixed(2)
          ) : (
            "-"
          )}
        </Typography>
      ),
      name: "Working Hours",
      sortable: true,
      selector: "working_hours",
    },
    
    {
      cell: (row) => (
        <Tooltip title={row.section} arrow>
          <Typography variant="body1" className="font-s-13">
            {row.section}
          </Typography>
        </Tooltip>
      ),
      name: "Section",
      selector: "section",
      sortable: true,
    },
    {
      cell: (row) => (
        <Tooltip title={row.location} arrow>
          <Typography variant="body1" className="font-s-13">
            {row.location}
          </Typography>
        </Tooltip>
      ),
      name: "Location",
      selector: "location",
      sortable: true,
    },
    {
      selector: (row) => (
        <Tooltip title={row.holiday_reason} arrow>
          <Typography
            variant="body1"
            className="font-s-22"
            style={{
              fontSize: "13px",
              color:
                row.status === "Late"
                  ? "#D3371C"
                  : row.status === "H"
                  ? "#FFAA2A"
                  : "#001C28",
            }}
          >
            {row.status && row.status}
          </Typography>
        </Tooltip>
      ),
      name: "Attendance",
      sortable: false,
    },
    {
      selector: (row) => (
        console.log(`${SERVER_KEY}${row.punch_in_photo}`),

        row.punch_in_photo && row.punch_in_photo !== "/"  ? (
          <Tooltip title="Click to view photo" arrow>
            <Typography
              variant="body1"
              className="font-s-13"
              component="a"
              href={`${SERVER_KEY}${row.punch_in_photo}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{  cursor: "pointer", textDecoration: "underline" }}
            >
              Show Photo
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="body1" className="font-s-13">No Photo</Typography>
        )
      ),
      name: "Punch In Pic",
      sortable: false,
      omit: role === "Teacher",
    },
    {
      selector: (row) => (
        row.punch_out_photo && row.punch_out_photo !== "/"  ? (
          <Tooltip title="Click to view photo" arrow>
            <Typography
              variant="body1"
              className="font-s-13"
              component="a"
              href={`${SERVER_KEY}${row.punch_out_photo}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Show Photo
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="body1" className="font-s-13">No Photo</Typography>
        )
      ),
      name: "Punch Out Pic",
      sortable: false,
      omit: role === "Teacher",
    },
    
  ];

  const teacher =  {
    cell: (row) => (
      <Typography variant="body1" className="font-s-13">
        {row.teacher}
      </Typography>
    ),
    name: "Trainer",
    sortable: true,
    selector: "teacher",
}

if (role !== "Teacher") {
  tableColumns.splice(1, 0, teacher);
}


  const [filterData, setFilterData] = useState({});
  const [location, setLocation] = useState({});
  const [filtersId, setFiltersId] = useState();
  const [optionData, setOptionData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reRender, setReRender] = useState(false);
  const[id,setId]=useState(null);
  const [isEmailsModalOpen, setIsEmailsModalOpen] = useState(false);
  const [submittedEmails, setSubmittedEmails] = useState([]);


  const handleOpenEmailsModal = () => {
    setIsEmailsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEmailsModalOpen(false);
  };

  const handleEmailsSubmit = (emailsList) => {
    // Handle the submitted emails list
    setSubmittedEmails(emailsList);
    console.log('Submitted emails:', emailsList);
    // You can add further logic here, like sending an email or updating state
  };

  const dates = getDatesById(filterData.section_id, optionData);

  const [filters, setFilters] = useState({});
  const [modalOpen, setModalOpen] = useState(false);


  const handleUpdate = (name, value) => {
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setFiltersId((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "teacher_id") {
      setFilterData({
        teacher_id: value,
        cohort_id: null,
        section_id: null,
        from_date: null,
        to_date: null,
      });
    }
    if (name === "location") {
      setLocation((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleFilter = () => {
    let obj = {
      section_id: filterData.section_id,
      teacher_id: filterData.teacher_id,
      from_date: formatDate(filterData.from_date),
      to_date: formatDate(filterData.to_date),
    };

    for (let key in obj) {
      if (obj[key] === "") {
        delete obj[key];
      }
    }
    setFilters(obj);
  };

  const handleOpenModal = React.useCallback((id) => {
    console.log(id, "queryParam");
    setId(id);
    setModalOpen((prev) => prev === true ? prev : true);
  }, []);

  const queryParam = Object.assign({}, filters, location);

  return (
    <>
      <Box className="container">
        <Box class="course-content">
          <h1 class="main-heading">Attendance</h1>
        </Box>

        <Grid
          container
          spacing={2}
          className={styles.attendance_wrap}
          marginTop={2}
        >
          {role !== "Teacher" && (
            <>
              <Box className={styles.filter_container_Teacher}>
                <Box className={`${styles.item} ${styles.item_1}`}>
                  <Typography variant="body2">Location</Typography>
                  <Box className={styles.select_box}>
                    <SelectOptionsApi
                      apiEndpoint={AdminApi.LOCATION_PAGINATE}
                      formData={filterData}
                      onUpdate={handleUpdate}
                      labelKey="name"
                      name="location"
                      valueKey="id"
                      errorText=""
                      paramId={""}
                      defaultText="Select Location"
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}

          <Box className={styles.filter_container}>
            {role !== "Teacher" && (
              <>
                <Box className={`${styles.item} ${styles.item_1}`}>
                  <Typography variant="body2">Teacher</Typography>
                  <Box className={styles.select_box}>
                    <SelectOptionsApi
                      apiEndpoint={AdminApi.TEACHERS_LIST}
                      formData={filterData}
                      onUpdate={handleUpdate}
                      labelKey="name"
                      name="teacher_id"
                      valueKey="id"
                      errorText=""
                      defaultText="Select Teacher"
                    />
                  </Box>
                </Box>

                <Box className={`${styles.item} ${styles.item_3}`}>
                  <Typography variant="body2" className={styles.Cohort_label}>
                    Section
                  </Typography>
                  <Box className={styles.select_box}>
                    <SelectOptionsApi
                      apiEndpoint={
                        filtersId && filtersId.teacher_id
                          ? AdminApi.TEACHERS_SECTIONS
                          : null
                      }
                      formData={filterData}
                      onUpdate={handleUpdate}
                      labelKey="name"
                      name="section_id"
                      valueKey="id"
                      errorText=""
                      paramId={filtersId ? `${filtersId.teacher_id}` : ""}
                      optionData={setOptionData}
                      defaultText="Select Section"
                      disabled={!filtersId || !filtersId.teacher_id}
                    />
                  </Box>
                </Box>
              </>
            )}
            <Box className={`${styles.item} ${styles.item_4}`}>
              <Typography variant="body2" className={styles.Cohort_label}>
                From Date
              </Typography>
              <Box className={styles.select_box}>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  name="from_date"
                  selected={
                    filterData.from_date ? new Date(filterData.from_date) : null
                  }
                  onChange={(date) =>
                    setFilterData((prevFormData) => ({
                      ...prevFormData,
                      from_date: date,
                    }))
                  }
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date From"
                  minDate={dates && new Date(dates.start_date)}
                  maxDate={dates && new Date(dates.end_date)}
                  startDate={filterData && filterData.from_date}
                  endDate={filterData && filterData.to_date}
                />
              </Box>
            </Box>
            <Box className={`${styles.item} ${styles.item_5}`}>
              <Typography variant="body2" className={styles.Cohort_label}>
                Date To
              </Typography>
              <Box className={styles.select_box}>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  required
                  id="outlined-required"
                  fullWidth
                  selected={
                    filterData.to_date ? new Date(filterData.to_date) : null
                  }
                  name="to_date"
                  onChange={(date) =>
                    setFilterData((prevFormData) => ({
                      ...prevFormData,
                      to_date: date,
                    }))
                  }
                  showTimeSelect={false}
                  size="small"
                  placeholderText="Date To"
                  minDate={dates && new Date(dates.start_date)}
                  maxDate={dates && new Date(dates.end_date)}
                  startDate={filterData && filterData.from_date}
                  endDate={filterData && filterData.to_date}
                />
              </Box>
              <Button
                sx={{
                  backgroundColor: "rgba(0, 28, 40, 1)",
                  color: "#fff",
                  height: "40px",
                  marginLeft: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 28, 40, 1)",
                    color: "#fff",
                    opacity: "0.8",
                  },
                }}
                onClick={() => {
                  handleFilter();
                }}
              >
                Go
              </Button>

            </Box>
            {role !== "Teacher" && (
              <>
              <Button
                sx={{
                  backgroundColor: "rgba(0, 28, 40, 1)",
                  color: "#fff",
                  height: "40px",
                  width: "100px",
                  marginLeft: "30px",
                    "&:hover": {
                    backgroundColor: "rgba(0, 28, 40, 1)",
                    color: "#fff",
                    opacity: "0.8",
                  },
                }}
                onClick={() => {
                  handleOpenEmailsModal();
                }}
              >
                ADD EMAIL
              </Button>
             
              <AddEmailModal 
        open={isEmailsModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleEmailsSubmit}
      />
          <Button
                sx={{
                  backgroundColor: "rgba(0, 28, 40, 1)",
                  color: "#fff",
                  height: "40px",
                  width: "400",
                  marginLeft: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 28, 40, 1)",
                    color: "#fff",
                    opacity: "0.8",
                  },
                }}
                onClick={() => {
                  setIsModalOpen(true)
                }}
              >
                Add Attendance
              </Button>
              <AddTrainerAttendance
               open={isModalOpen}
               closeModal={() => setIsModalOpen(false)}
               setReRender={setReRender}
/>
</>
            )}
            
          </Box>
          
          <Grid item xl={12} lg={12} md={12} xs={12} className="table-phases ">
            <Datatable
              tableColumns={tableColumns}
              dataUrl={AdminApi.TEACHERS_CHECKINS_VIEW}
              actionColumns={role !== "Teacher"}
              qureyParam={queryParam}
              onEdit={handleOpenModal}
              actions={{
                view: false,
                delete:false
              }}
              module="manage-trainer-attendence"
            />
          </Grid>
        </Grid>
        <EditCheckIns modalOpen={modalOpen} setModalOpen={setModalOpen} id={id}/>
      </Box>
    </>
  );
}
