import * as Yup from "yup";

const isRequiredIfSingle = function (value) {
  return this.parent.marital_status === "Single" ? !!value : true;
};
const isRequiredIfMarried = function (value) {
  return this.parent.marital_status === "Married" ? !!value : true;
};

const validationSchema = Yup.object().shape({
  student_id: Yup.number().required("This field is required"),
  cohort: Yup.string().required("This field is required"),
  section: Yup.string().required("This field is required"),
  // registration_date: Yup.string().required("This field is required"),
  // shift_days: Yup.string().required("This field is required"),
  // shift_time: Yup.string().required("This field is required"),
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  cnic: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required"),
  residential_area: Yup.string().required("This field is required"),
  country_id: Yup.string().required("This field is required"),
  state_id: Yup.string().required("This field is required"),
  city_id: Yup.string().required("This field is required"),
  address: Yup.string().required("This field is required"),
  phone: Yup.string()
    .matches(/.*[0-9].*/, "Must contain at least one number")
    .required("This field is required"),
  emergency_number: Yup.string()
    .matches(/.*[0-9].*/, "Must contain at least one number")
    .required("This field is required"),
  date_of_birth: Yup.string().required("This field is required"),
  marital_status: Yup.string().required("This field is required"),
  father_name: Yup.string().test({
    test: isRequiredIfSingle,
    message: "This field is required",
  }),
  father_profession: Yup.string().test({
    test: isRequiredIfSingle,
    message: "This field is required",
  }),
  mother_profession: Yup.string().test({
    test: isRequiredIfSingle,
    message: "This field is required",
  }),
  number_of_siblings: Yup.string().test({
    test: isRequiredIfSingle,
    message: "This field is required",
  }),
  siblings_education_numbers: Yup.string().test({
    test: isRequiredIfSingle,
    message: "This field is required",
  }),
  husband_name: Yup.string().test({
    test: isRequiredIfMarried,
    message: "This field is required",
  }),
  husband_name: Yup.string().test({
    test: isRequiredIfMarried,
    message: "This field is required",
  }),
  husband_profession: Yup.string().test({
    test: isRequiredIfMarried,
    message: "This field is required",
  }),
  number_of_kids: Yup.string().test({
    test: function (value) {
      const isRequired = ["Married", "Divorced", "Widow"].includes(
        this.parent.marital_status
      );
      return isRequired ? !!value : true;
    },
    message: "This field is required",
  }),
  kids_education_numbers: Yup.string().test({
    test: function (value) {
      const isRequired = ["Married", "Divorced", "Widow"].includes(
        this.parent.marital_status
      );
      return isRequired ? !!value : true;
    },
    message: "This field is required",
  }),
  average_income: Yup.string().required("This field is required"),
  highest_qualifications: Yup.string().required("This field is required"),
  other_qualifications: Yup.string().test({
    test: function (value) {
      return this.parent.highest_qualifications === "Others" ? !!value : true;
    },
    message: "This field is required",
  }),
  additional_diplomas_certifications: Yup.string().required(
    "This field is required"
  ),
  diplomas_certifications_details: Yup.string().test({
    test: function (value) {
      return this.parent.additional_diplomas_certifications === "Yes"
        ? !!value
        : true;
    },
    message: "This field is required",
  }),
  professional_experience: Yup.string().required("This field is required"),
  experience_years: Yup.string().test({
    test: function (value) {
      return this.parent.professional_experience === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),
  currently_employed: Yup.string().test({
    test: function (value) {
      return this.parent.professional_experience  === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),
  position: Yup.string().test({
    test: function (value) {
      return this.parent.currently_employed === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),
  salary: Yup.string().test({
    test: function (value) {
      return this.parent.currently_employed === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),
  employer_name: Yup.string().test({
    test: function (value) {
      return this.parent.currently_employed === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),

  tech_training: Yup.string().required("This field is required"),
  tech_training_details: Yup.string().test({
    test: function (value) {
      return this.parent.tech_training === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),
  household_items: Yup.array()
    .of(Yup.string().required("Each household item must have a valid name"))
    .min(1, "At least one household item is required"),
  smartphone: Yup.string().required("This field is required"),
  laptopdesktop: Yup.string().required("This field is required"),
  usually_commute: Yup.string().required("This field is required"),
  usually_commute_other: Yup.string().test({
    test: function (value) {
      return this.parent.usually_commute === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),
  program_commute: Yup.string().required("This field is required"),
  program_commute_other: Yup.string().test({
    test: function (value) {
      return this.parent.program_commute === "Yes" ? !!value : true;
    },
    message: "This field is required",
  }),
  skills: Yup.array()
    .of(Yup.string().required("This field is required"))
    .min(1, "At least one skill is required"),
  join_reason: Yup.string().required("This field is required"),
  expect_learn: Yup.string().required("This field is required"),
});

export default validationSchema;
