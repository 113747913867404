import { useMemo } from "react";
import { useStudentReportContext } from "../../../../../context/StudentReportContext";
import { DateView } from "../../../../../components/DateView/DateView";
import { SERVER_KEY } from "../../../../../config/constants/EnviormentVariables";

const useStudentsReports = () => {
  const { studentReports } = useStudentReportContext();

  const capitalizeAndRemoveUnderscore = (str) => {
    return str.replace(/_/g, " ").replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const { columns, modifiedData, csvData } = useMemo(() => {
    const modifiedData =
      Array.isArray(studentReports) && studentReports.length > 0
        ? studentReports.map((item, index) => {
            const { id, name, email, ...rest } = item;
            const SN = `${(index + 1).toString().padStart(3, "0")}`;
            return {
              SN,
              id,
              name,
              email,
              ...rest,
            };
          })
        : [];

    const columns =
      modifiedData.length > 0
        ? [...new Set(modifiedData.flatMap(Object.keys))].map((key) => ({
            name: capitalizeAndRemoveUnderscore(key),
            selector: (row) =>
              key.includes("date") ? (
                row[key] && <DateView date={row[key]} />
              ) : key.includes("resume") && row[key] ? (
                <a
                  href={`${SERVER_KEY}${row[key]}`}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#022d40", textDecoration: "underline" }}
                >
                  Download Resume
                </a>
              ) : (
                row[key]
              ),
            sortable: true,
            wrap: true,
            minWidth: "200px",
          }))
        : [];

    const transformKeys = (obj) => {
      const newObj = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase());
          newObj[newKey] = obj[key];
        }
      }
      return newObj;
    };

    const csvData = modifiedData.map((item) => transformKeys(item));

    return { columns, modifiedData, csvData };
  }, [studentReports]);

  return { columns, modifiedData, csvData };
};

export default useStudentsReports;
