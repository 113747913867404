import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { api } from "../../../config/apis";
import AdminApi from "../../../config/apis/admin";
import { Box, Paper, Button, Typography } from '@mui/material';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const MarkCheckIns = () => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const nevigate = useNavigate();

  const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user",
  };


  const capture = () =>{
      if (webcamRef.current) {
      const screenshot = webcamRef.current.getScreenshot();
      if (screenshot) {
        setImageSrc(screenshot);
        console.log("Captured image:", screenshot);
        sendImageToAPI(screenshot);
      } else {
        console.error(
          "Failed to capture image. Make sure the webcam is loaded."
        );
      }
    }
  }

  const sendImageToAPI = async (base64Image) => {
    try {
      const byteCharacters = atob(base64Image.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });
  
      const formData = new FormData();
      const fileName = `screenshot_${Date.now()}.jpg`;
      formData.append("photo", blob, fileName);
      formData.append("time", currentTime);
  
      const response = await api.post(AdminApi.TEACHERS_CHECKINS, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      toast.success(response.data.message);
      setTimeout(()=>{
        nevigate('/admin')
      },1000)
      
      console.log("API Response:", response.data);
    } catch (error) {
      toast.error(error.response.data.error);
      console.error("Error uploading image:", error.response.data.error);
    }
  };


  const currentDate = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "2-digit",
  });
  const currentTime = new Date().toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <Paper
      elevation={3}
      sx={{
        marginTop:8,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!imageSrc ? (
        <Box
          sx={{
            borderRadius: 2,
            border: "1px solid #D1D5DB", 
            lineHeight:0
          }}
        >
          <Webcam
            audio={false}
            height={300}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={300}
            videoConstraints={videoConstraints}
            style={{ borderRadius: 8 }}
          />
        </Box>
      ) : (
        <Box
          component="img"
          src={imageSrc}
          alt="Captured"
          sx={{ borderRadius: 2, border: "1px solid #D1D5DB" }}
        />
      )}

      <Typography
        align="center"
        variant="h6"
        sx={{ mt: 2, fontWeight: "bold" }}
      >
        {currentDate && currentDate}
        <br />
        {currentTime && currentTime}
      </Typography>

      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          onClick={capture}
          sx={{
            backgroundColor: "#16A34A", 
            "&:hover": { backgroundColor: "#15803D" },
            boxShadow: 3,
          }}
        >
          Mark
        </Button>
        <Button
          variant="contained"
          onClick={() => nevigate('/admin')}
          sx={{
            backgroundColor: "#DC2626", 
            "&:hover": { backgroundColor: "#B91C1C" }, 
            boxShadow: 3,
          }}
        >
          Cancel
        </Button>
      </Box>
    </Paper>
  );
};

export default MarkCheckIns;
